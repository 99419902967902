import { Component, OnInit } from "@angular/core";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "app-mentions",
  templateUrl: "mentions.component.html",
  styleUrls: ["./mentions.component.scss"]
})
export class MentionsComponent implements OnInit {
  constructor(private seoService:SeoService) {}
  ngOnInit() {
    this.seoService.setSeoTags({
      titleKey:"seo.mentions.title", 
      descriptionKey:"seo.mentions.description",
      imageKey: "seo.mentions.image"
    });
  }
}
