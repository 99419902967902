<div class="mb-2">
  <small>{{ 'login.signin' | translate }}</small>
</div>

<form role="form" [formGroup]="form" (ngSubmit)="submitPirogProvider()">
  <!-- Email Field -->
  <div class="form-group mb-3" [ngClass]="{ focused: focus === true }">
    <div class="input-group input-group-alternative" (click)="$event.stopPropagation()">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="ni ni-email-83"></i></span>
      </div>
      <input
        class="form-control"
        placeholder="Email"
        type="email"
        formControlName="email"
        autocomplete="on"
      />
    </div>
    <div *ngIf="isFieldInvalid('email')" class="text-warning">
      <small *ngIf="hasError('email', 'required')">{{ 'login.email_required' | translate }}</small>
      <small *ngIf="hasError('email', 'email')">{{ 'login.email_invalid' | translate }}</small>
    </div>
  </div>

  <!-- Password Field -->
  <div class="form-group" [ngClass]="{ focused: focus1 === true }">
    <div class="input-group input-group-alternative" (click)="$event.stopPropagation()">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
      </div>
      <input
        class="form-control"
        placeholder="{{ 'login.placeholder' | translate }}"
        formControlName="password"
        [type]="showPassword ? 'text' : 'password'"
        (focus)="focus1 = true"
        (blur)="focus1 = false"
        autocomplete="on"
      />
      <div class="input-group-append" (click)="$event.stopPropagation()">
        <button class="btn btn-outline-primary" type="button" (click)="toggleShowPassword()">
          <i *ngIf="!showPassword" class="fa fa-eye"></i>
          <i *ngIf="showPassword" class="fa fa-eye-slash"></i>
        </button>
      </div>
    </div>
    <div *ngIf="isFieldInvalid('password')" class="text-warning">
      <small *ngIf="hasError('password', 'required')">{{ 'login.password_required' | translate }}</small>
      <small *ngIf="hasError('password', 'minlength')">{{ 'login.password_min_length' | translate: { minLength: 8 } }}</small>
    </div>
  </div>

  <!-- Forgot Password and Submit Button -->
  <div class="row justify-content-between mb-3">
    <div class="col-6 m-auto">
      <a (click)="redirectToForgotPasswordPage()">
        <h5 class="mt-2 font-weight-light">{{ 'login.a_0' | translate }}</h5>
      </a>
    </div>

    <div class="col-6 text-right" (click)="$event.stopPropagation()">
      <button type="submit" class="btn btn-sm btn-secondary" [disabled]="form.invalid">
        {{ 'login.signin' | translate }}
      </button>
    </div>
  </div>
</form>

<google-button></google-button>

<hr class="m-0 mt-3" />

<div class="row justify-content-center mt-3">
  <div class="col-6 m-auto">
    <a (click)="redirectToNoAccountPage()">
      <h5 class="mt-2 font-weight-light">{{ 'login.no_account' | translate }}</h5>
    </a>
  </div>
</div>
