import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Template } from 'src/app/models/template.model';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html'
})
export class StoreComponent {
  template: Template;
  idTemplate: string | null = null;
  currentUserId: string;

  constructor() {}
}


