import { Component, Inject, OnInit, Optional } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { RESPONSE } from "../../../../../express.tokens";
import { TranslateService } from "@ngx-translate/core";
import { Response } from "express";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "not-found",
  templateUrl: "not-found.component.html"
})
export class NotFoundComponent implements OnInit {
  focus2;
  constructor(@Optional() @Inject(RESPONSE) private response: Response ,private seoService: SeoService) {
    if(this.response) {
      // TODO BUG : this.response = null
      // il faut que express récupère la réponse et retourne cette 404
      //this.response.status(404);
    }
  }

  ngOnInit() {
    this.seoService.setSeoTags({
      titleKey:"seo.not_found.title", 
      descriptionKey:"seo.not_found.description", 
      imageKey: "seo.not_found.image",
      noindex:true});
  }
}
