import { Component, Input } from '@angular/core';
import { Template } from 'devextreme/core/templates/template';
import { ApplicationPublic } from 'src/app/models/application-public.model';
import { Application } from 'src/app/models/application.model';

@Component({
  selector: 'app-micro-app-card',
  templateUrl: './micro-app-card.component.html',
  styleUrls: ['./micro-app-card.component.scss']
})
export class MicroAppCardComponent {
  @Input() application: ApplicationPublic;
  @Input() isChecked: boolean = false;
  buttonDict: { [key: string]: string } = {
    piTransfer: 'Open',
    piFunction: 'Run',
    piChat: 'Chat' ,
    piProfile:'View'
  };
}
