<section class="p-4">
  <div class="container d-flex justify-content-center">
    <div class="row d-flex align-items-center justify-content-center gradient-bg gradient-round">
      <div class="d-flex flex-column justify-content-center bg-white gradient-round p-4">
        <p [innerHTML]="'login.h2.1' | translate" class="m-3"></p>
        <span class="m-3">
          <google-button></google-button>
        </span>
      </div>
    </div>
  </div>
</section>

<section class="p-4">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col">
        <div class="card bg-primary-light-3 border-0">
          <div class="card-header bg-transparent pb-5">
            <div class="text-muted text-center mt-2 mb-3">
              <h2 class="display-3 text-primary">{{ 'login.h2.2' | translate }}</h2>
            </div>
          </div>
          <div class="card-body px-lg-5 py-lg-5">
            <form role="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <!-- Email Field -->
              <div class="form-group">
                <label for="email">{{ 'login.p.2' | translate }}</label>
                <input
                  class="form-control bg-white"
                  placeholder="Email"
                  type="email"
                  formControlName="email"
                  autocomplete="on"
                />
                <div *ngIf="isFieldInvalid('email')" class="text-warning">
                  <small *ngIf="hasError('email', 'required')">{{ 'login.email_required' | translate }}</small>
                  <small *ngIf="hasError('email', 'email')">{{ 'login.email_invalid' | translate }}</small>
                </div>
              </div>

              <!-- Password Field -->
              <div class="form-group">
                <label for="password">{{ 'login.p.3' | translate }}</label>
                <div class="input-group input-group-alternative ">
                  <input
                    class="form-control bg-white"
                    placeholder="{{ 'login.placeholder' | translate }}"
                    type="{{ showPassword ? 'text' : 'password' }}"
                    formControlName="password"
                    autocomplete="on"
                  />
                  <div class="input-group-append ">
                    <button class="btn btn-outline-primary" type="button" (click)="toggleShowPassword()">
                      <i *ngIf="!showPassword" class="fa fa-eye"></i>
                      <i *ngIf="showPassword" class="fa fa-eye-slash"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="isFieldInvalid('password')" class="text-warning">
                  <small *ngIf="hasError('password', 'required')">{{ 'login.password_required' | translate }}</small>
                  <small *ngIf="hasError('password', 'minlength')">{{ 'login.password_min_length' | translate: { minLength: 8 } }}</small>
                </div>
              </div>

              <a routerLink="/forgot-password">
                <small>{{ 'login.a_0' | translate }}</small>
              </a>
              <div class="text-center">
                <button type="submit" class="btn btn-primary my-4" [disabled]="loginForm.invalid">
                  {{ 'login.signin' | translate }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
