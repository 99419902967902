import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { SeoService } from 'src/app/services/seo.service';
import { resetPasswordProcess } from 'src/app/store/auth/auth.actions';
import { selectErrorMessage } from 'src/app/store/auth/auth.selectors';

@Component({
  selector: 'app-reset-password-process',
  templateUrl: './reset-password-process.component.html',
  styleUrls: ['./reset-password-process.component.scss'],
})
export class ResetPasswordProcessComponent implements OnInit, OnDestroy {
  resetPasswordProcessForm = new FormGroup(
    {
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      confirmPassword: new FormControl('', Validators.required),
    },
    { validators: this.matchPasswords },
  );

  subscription: Subscription = new Subscription();
  error$: Observable<string>;
  token: string | null;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private seoService: SeoService,
  ) {}

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: 'seo.password_reset_process.title',
      descriptionKey: 'seo.password_reset_process.description',
      imageKey: 'seo.password_reset_process.image',
      noindex: true,
    });

    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        this.token = params['token'] || '';
      }),
    );

    this.error$ = this.store.select(selectErrorMessage);
  }

  matchPasswords({ value }: FormGroup): { mismatch: boolean } | null {
    const { password, confirmPassword } = value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  hasPasswordMismatchError(): boolean {
    const formErrors = this.resetPasswordProcessForm.errors;
    return formErrors?.mismatch && this.resetPasswordProcessForm.touched;
  }

  onSubmit(): void {
    if (!this.resetPasswordProcessForm.valid || !this.token) {
      this.resetPasswordProcessForm.markAllAsTouched();
      return;
    }
    const { password } = this.resetPasswordProcessForm.value;
    this.store.dispatch(
      resetPasswordProcess({
        resetPasswordProcessRequest: {
          newPassword: password,
          token: this.token,
        },
      }),
    );
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  isFieldInvalid(fieldName: string): boolean {
    const field = this.resetPasswordProcessForm.controls[fieldName];
    return field.invalid && field.touched;
  }
  
  hasError(fieldName: string, errorType: string): boolean {
    const field = this.resetPasswordProcessForm.controls[fieldName];
    return field.hasError(errorType);
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
