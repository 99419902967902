import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { SeoService } from 'src/app/services/seo.service';
import { verifyAccount } from 'src/app/store/auth/auth.actions';
import { selectVerifyAccountSuccess, selectErrorMessage } from 'src/app/store/auth/auth.selectors';
import { selectUrlOrderFromStep } from 'src/app/store/order/order.selectors';

@Component({
    selector: 'app-account-validation',
    templateUrl: './account-validation.component.html',
    styleUrls: ['./account-validation.component.scss'],
})
export class AccountValidationComponent implements OnInit, OnDestroy {
    verifyAccountSuccess$: Observable<boolean | null>;
    error$: Observable<any>;
    subscription: Subscription = new Subscription();
    urlOrderFromStep$: Observable<string[]>;
    
    constructor(
        private route: ActivatedRoute,
        private store: Store, 
        public router: Router, 
        public seoService: SeoService) {}
    
    ngOnInit(): void {
        this.seoService.setSeoTags({
            titleKey:"seo.account_validation.title", 
            descriptionKey:"seo.account_validation.description", 
            imageKey:"seo.account_validation.image",
            noindex:true});
        this.verifyAccountSuccess$ = this.store.select(selectVerifyAccountSuccess);
        this.urlOrderFromStep$ = this.store.select(selectUrlOrderFromStep);

        this.error$ = this.store.select(selectErrorMessage);
        this._queryParams();
    }

    private _queryParams() {
        const _queryParams = this.route.queryParams.subscribe((query) => {
            this.store.dispatch(verifyAccount({ token: query['token'] }));
        });
        this.subscription.add(_queryParams);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}