<div class="header bg-white py-7 py-lg-8 pt-lg-9">
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
          <h1 class="display-3">{{ 'mentions.h1' | translate }}</h1>
          <h2 class="lead">{{ 'mentions.h2.1' | translate }}</h2>
          <h3 class="text-lead">{{ 'mentions.h3' | translate }}</h3>
          <h3 class="text-lead">{{ 'mentions.h4' | translate }}</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="header-body text-center mb-7 mx-3">
      <div class="row justify-content-center">
        <div>
          <h2>{{ 'mentions.h2.2' | translate }}</h2>
          <p>{{ 'mentions.p_0' | translate }}</p>
        </div>
        <div>
          <h2>{{ 'mentions.h2.3' | translate }}</h2>
          <p>{{ 'mentions.p_1' | translate }}</p>
        </div>
        <div>
          <h2>{{ 'mentions.h2.4' | translate }}</h2>
          <p>{{ 'mentions.p_2' | translate }}</p>
        </div>
        <div>
          <h2>{{ 'mentions.h2.5' | translate }}</h2>
          <p>{{ 'mentions.p_3' | translate }}</p>
        </div>
        <div>
          <h2>{{ 'mentions.h2.6' | translate }}</h2>
          <p>{{ 'mentions.p_4' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="separator separator-bottom separator-skew zindex-100">
    <svg
      x="0"
      y="0"
      viewBox="0 0 2560 100"
      preserveAspectRatio="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>
