<page-header [pageName]="'reset_pass.title'" [mainTitle]="'reset_pass.h1'" [description]="'reset_pass.p_0'"
  [bgColorClass]="'bg-primary-light-3'" [iconPath]="'assets/img/icons/common/icon_account.png'">
</page-header>
<div class="d-flex flex-column align-items-center p-5">
  <div class="card bg-primary-light-3 border-0 w-100" style="max-width: 500px">
    <div class="card-body">
      <h4 class="text-center mb-4">
        {{ 'reset_pass.small_0' | translate }}
      </h4>
      <form role="form" (ngSubmit)="onSubmit()" [formGroup]="resetPasswordAttemptForm">
        <div class="form-group mb-3">
          <label for="email"><small>{{ 'login.p.2' | translate }}</small></label>
          <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="ni ni-email-83"></i></span>
            </div>
            <input id="email" class="form-control" placeholder="{{ 'reset_pass.input_0' | translate }}" type="email"
              name="email" formControlName="email" autocomplete="on" />
          </div>
          <div *ngIf="isFieldInvalid('email')" class="text-warning">
            <small *ngIf="hasError('email', 'required')">{{ 'reset_pass.email_required' | translate }}</small>
            <small *ngIf="hasError('email', 'email')">{{ 'reset_pass.email_invalid' | translate }}</small>
          </div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-primary my-4">
            {{ 'reset_pass.button_0' | translate }}
          </button>
        </div>
      </form>

    </div>
  </div>
  <div class="d-flex justify-content-between w-100" style="max-width:500px">

    <a routerLink="/login" class="">
      <small> {{ 'reset_pass.small_1' | translate }} </small>
    </a>
    <a class="" [routerLink]="['/register']">
      <small> {{ 'reset_pass.small_2' | translate }} </small>
    </a>
  </div>
</div>