
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { loadTemplates } from "src/app/store/repository/template/template.actions";

@Component({
  selector: "app-public",
  templateUrl: "./public.component.html",
  styleUrls: ["./public.component.scss"]
})
export class PublicComponent implements OnInit {

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(loadTemplates());
  }
}

