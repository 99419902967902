import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PlatformService } from 'src/app/services/plateform.service';
import { signUp } from 'src/app/store/auth/auth.actions';
import { selectErrorAuth } from 'src/app/store/auth/auth.selectors';

@Component({
  selector: 'app-register-section',
  templateUrl: 'register-section.component.html',
})
export class RegisterSectionComponent implements OnInit {
  focus;
  focus1;
  focus2;
  focus3;
  registerForm: FormGroup;
  error$: Observable<any>;
  showPassword: boolean = false;
  language: string;

  constructor(
    private store: Store,
    private platformService: PlatformService,
    private localStorageService: LocalStorageService
  ) {
    // Initialize the form directly as registerForm
    this.registerForm = new FormGroup({
      lastname: new FormControl('', Validators.required),
      firstname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      agreePolicy: new FormControl(false, Validators.requiredTrue),
      identityProvider: new FormControl('PIROG')
    });
  }

  ngOnInit(): void {
    this.error$ = this.store.select(selectErrorAuth);
  }

  // Method to check if a specific field is invalid and touched
  isFieldInvalid(fieldName: string): boolean {
    const control = this.registerForm.get(fieldName);
    return control && control.invalid && control.touched;
  }

  // Method to check if a specific field has a particular error
  hasError(fieldName: string, error: string): boolean {
    const control = this.registerForm.get(fieldName);
    return control && control.hasError(error);
  }

  submitPirogProvider() {
    if (this.registerForm.valid && this.platformService.isPlatformBrowser()) {
      let user = this.registerForm.value as User;
      this.language = this.localStorageService.getItem("selectedLanguage") || navigator.language.split('-')[0];
      user.language = this.language;
      this.store.dispatch(signUp({ user }));
    } else {
      this.registerForm.markAllAsTouched(); // Mark all fields as touched to show errors
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
