<div class="container">
  <section class="my-6">
    <div class="row">
      <div class="col-12 col-lg-6">
        <!-- Form start -->
        <form (ngSubmit)="onSubmit()" role="form" [formGroup]="form">
          <!-- Name Field -->
          <div class="form-group">
            <label for="name">{{ 'contact.p.2' | translate }}</label>
            <input type="text" name="name" class="form-control" id="name" formControlName="name">
            <div *ngIf="isFieldInvalid('name')" class="text-warning">
              <small *ngIf="hasError('name', 'required')">
                {{ 'contact.errors.name_required' | translate }}
              </small>
            </div>
          </div>

          <!-- Email Field -->
          <div class="form-group">
            <label for="email">{{ 'contact.p.3' | translate }}</label>
            <input type="email" class="form-control" name="email" id="email" formControlName="email">
            <div *ngIf="isFieldInvalid('email')" class="text-warning">
              <small *ngIf="hasError('email', 'required')">
                {{ 'contact.errors.email_required' | translate }}
              </small>
              <small *ngIf="hasError('email', 'email')">
                {{ 'contact.errors.email_invalid' | translate }}
              </small>
            </div>
          </div>

          <!-- Subject Field -->
          <div class="form-group">
            <label for="subject">{{ 'contact.p.4' | translate }}</label>
            <input type="text" class="form-control" name="subject" id="subject" formControlName="subject">
            <div *ngIf="isFieldInvalid('subject')" class="text-warning">
              <small *ngIf="hasError('subject', 'required')">
                {{ 'contact.errors.subject_required' | translate }}
              </small>
            </div>
          </div>

          <!-- Message Field -->
          <div class="form-group">
            <label for="message">{{ 'contact.p.5' | translate }}</label>
            <textarea class="form-control" name="message" rows="10" formControlName="message"></textarea>
            <div *ngIf="isFieldInvalid('message')" class="text-warning">
              <small *ngIf="hasError('message', 'required')">
                {{ 'contact.errors.message_required' | translate }}
              </small>
            </div>
          </div>

          <!-- reCAPTCHA and Submit Button -->
          <div class="mb-2">
            <re-captcha (resolved)="resolved($event)" [siteKey]="recaptchaSecretKey"></re-captcha>
          </div>

          <div class="text-center">
            <button class="btn btn-dark" type="submit" [disabled]="form.invalid">
              {{ 'contact.button.1' | translate }}
            </button>
          </div>
        </form>
        <!-- Form end -->
      </div>
      <div class="col-6 text-center d-none d-lg-block">
        <img src="assets/img/icons/elements/three_wheels_2.png">
      </div>
    </div>
  </section>
</div>

<app-products-banner></app-products-banner>
<app-advantages-banner></app-advantages-banner>
