import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-contact-banner",
  templateUrl: "./contact-banner.component.html",
  styleUrls: ["./contact-banner.component.scss"]
})
export class ContactBannerComponent {

  constructor(private router: Router) {}

  // onButtonClick(): void {
  //   this.router.navigate(['/contact']);
  // }
}
