import { Injectable } from '@angular/core';
import { ApplicationStep } from '../store/order/models/application-step.model';
import { DomainStep } from '../store/order/models/domain-step.model';
import { OptionsStep } from '../store/order/models/options-step.model';
import { LocalStorageService } from './local-storage.service';
import { AppCreationOrder } from '../store/order/models/creation-order.model';

enum OrderLocalStorageKey {
    ApplicationStep = 'applicationStep',
    DomainStep = 'domainStep',
    OptionsStep = 'optionsStep',
    Step = 'step',
    TemplateType = 'templateType',

    CreationOrder = 'creationOrder'
}

export enum Step {
    Application = 'application',
    Domain = 'domain',
    Options = 'options',
    Payment = 'payment',
}

@Injectable({
    providedIn: 'root',
})

export class OrderLocalStorageService {

    constructor(private localStorageService: LocalStorageService) {}

    setCreationOrder(AppCreationOrder: AppCreationOrder) {
        this.localStorageService.setObject(OrderLocalStorageKey.CreationOrder, AppCreationOrder);
    }

    getCreationOrder() {
        return this.localStorageService.getObject(OrderLocalStorageKey.CreationOrder);
    }

    clearCreationOrder() {
        this.localStorageService.removeItem(OrderLocalStorageKey.CreationOrder);
    }

    setStep(step: Step): void {
        this.localStorageService.setItem(OrderLocalStorageKey.Step, step);
    }

    getStep(): Step {
        return this.localStorageService.getItem(OrderLocalStorageKey.Step) as Step;
    }

    setTemplateType(templateType: string): void {
        this.localStorageService.setItem(OrderLocalStorageKey.TemplateType, templateType);
    }

    getTemplateType(): string {
        return this.localStorageService.getItem(OrderLocalStorageKey.TemplateType);
    }

    setApplicationStep(applicationStep: ApplicationStep): void {
        this.localStorageService.setObject(OrderLocalStorageKey.ApplicationStep, applicationStep);
    }

    setDomainStep(domainStep: DomainStep): void {
        this.localStorageService.setObject(OrderLocalStorageKey.DomainStep, domainStep);
    }

    setOptionsStep(optionsStep: OptionsStep): void {
        this.localStorageService.setObject(OrderLocalStorageKey.OptionsStep, optionsStep);
    }

    getDomainStep(): DomainStep {
        return this.localStorageService.getObject(OrderLocalStorageKey.DomainStep) as DomainStep;
    }

    getApplicationStep(): ApplicationStep {
        return this.localStorageService.getObject(OrderLocalStorageKey.ApplicationStep) as ApplicationStep;
    }

    getOptionsStep(): OptionsStep {
        return this.localStorageService.getObject(OrderLocalStorageKey.OptionsStep) as OptionsStep;
    }

    clearCart() {
        this.localStorageService.removeItem(OrderLocalStorageKey.ApplicationStep);
        this.localStorageService.removeItem(OrderLocalStorageKey.DomainStep);
        this.localStorageService.removeItem(OrderLocalStorageKey.OptionsStep);
        this.localStorageService.removeItem(OrderLocalStorageKey.TemplateType);
        this.setStep(Step.Application);
    }
}
