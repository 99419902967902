<!-- Header -->
<div class="header bg-custom">
  <div class="d-flex align-items-lg-center text-center shadow-1-strong text-white bg-landing p-3">
    <div class="m-auto">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="text-center">
              <h1 class="display-1 text-white my-4" [innerHTML]="'landing.hero.h1' | translate"></h1>

              <h3 class="text-white display-4 my-4" [innerHTML]="'landing.hero.h3' | translate"></h3>
              <div class="my-4">
                <a *ngIf="(isAuth$ | async) === false"  class="btn btn-light text-primary my-lg-2"(click)="scrollToSection()">
                  <span class="btn-inner--text"><strong>{{ 'landing.hero.button.discover' | translate }}</strong></span>
                </a>
                <a *ngIf="(isAuth$ | async) === true" [routerLink]="['/dashboard/applications']" class="btn btn-light text-primary my-lg-2">
                  <span class="btn-inner--text">{{ 'landing.hero.button.yourapps' | translate }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>


  <section #section2 id="landing-title" class="py-5 bg-white position-relative">
    <div class="container-fluid">
      <div>
        <h2 class="display-2 mx-auto my-2 text-center">
          <span [innerHTML]="'landing.hero.p.1' | translate"></span>
          <span class="text-red" [innerHTML]="'landing.hero.p.2' | translate"></span>
          <br>
          <span [innerHTML]="'landing.hero.p.3' | translate"></span>
          <span class="text-primary" [innerHTML]="'landing.hero.p.4' | translate"></span>
          <span [innerHTML]="'landing.hero.p.5' | translate"></span>
        </h2>
      </div>
    </div>
  </section>

  <section #section3 id="landing-caroussel" class="py-4 bg-white position-relative">
    <div class="container-fluid">
      <div class="row justify-content-center">
          <div *ngFor="let template of templates$ | async" class="col-6 border-custom-{{template.name}}">
            <div class="row flex-column flex-lg-row"
              [ngClass]="{'justify-content-start': ['t1','t2'].includes(template.id), 'justify-content-end': ['t3','t4'].includes(template.id)}">
              <div class="col col-lg-4 px-3 px-md-6 p-lg-2">
                <img src="assets/templates/{{template.name}}/{{template.id}}_home-min.webp" class="img-fluid">
              </div>
              <div class="col col-lg-6 px-3 p-lg-2">
                <h2 class="display-3 py-3">
                  <span>{{ template.id + '.public.h2.1' | translate }}</span>
                  <span [innerHTML]="template.id + '.public.h2.2' | translate" class="text-{{template.name}}"></span>
                  <span>{{ template.id + '.public.h2.3' | translate }}</span>
                </h2>
                <p class="font-weight-bold">{{ template.id + '.public.p.problem' | translate }}</p>
                <p [innerHTML]="template.id + '.public.p.problem_details' | translate"></p>
                <p class="font-weight-bold">{{ template.id + '.public.p.solution' | translate }}</p>
                <p [innerHTML]="template.id + '.public.p.solution_details' | translate"></p>
                <div class="mb-3">
                  <a [id]="'caroussel_' + template.id" [routerLink]="['/store/', template.name]" class="btn btn-{{template.name}} text-white my-lg-2" (click)="chooseTemplate(template)">
                    <span class="btn-inner--text"><strong>{{ template.id + '.public.button' | translate }}</strong></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>

<app-products-banner [details]="false" class="d-block d-sm-none"></app-products-banner>
<app-products-banner [details]="true" class="d-none d-sm-block"></app-products-banner>

<app-search-section></app-search-section>

<div class="container">
  <section #section6 id="landing-approach" class="py-6">
    <div class="row">
      <div class="col-12 col-lg-4 m-auto">
        <app-paragraph-section
          [titleKey]="'landing.approach.h2'"
          [descriptionKey]="'landing.approach.p'"
          [buttonKey]="'landing.approach.button'"
          [buttonClass]="'btn-primary'"
          [route]="'/about'"
          [wheelsArround]="true">
        </app-paragraph-section>
      </div>
      <div class="col-12 col-lg-8 m-auto position-relative">
        <img src="assets/img/icons/elements/wheel_big_small.png" class="wheel-big-custom position-absolute" style="z-index:1;">
        <app-template-youtube [youtubeLink]="'https://www.youtube.com/embed/3q2oGj7XK4w'" style="z-index: 2; position: relative;"></app-template-youtube>
      </div>

    </div>
  </section>
</div>

<section #section7 id="landing-testimonials" class="py-6">
  <div class="container-fluid bg-primary-light-3">
    <app-heading-section
      [titleKey]="'landing.testimonials.h2'"
      [descriptionKey]="'landing.testimonials.p'"
      [iconPath]="'assets/img/icons/common/thumb.png'">
    </app-heading-section>

    <app-testimonial-section
      [descriptionKey]="'landing.testimonials'"
      [bgCardClass]="'bg-white'"
      [primaryColorClass]="'primary'"
      [secondaryColorClass]="'secondary'">
    </app-testimonial-section>
  </div>
</section>

<section #section8 id="landing-kpi" class="container-fluid d-flex justify-content-center">
  <div class="container">
    <div class="row d-flex align-items-center justify-content-center gradient-bg gradient-round">
      <div class="d-flex justify-content-center bg-white gradient-round py-5 px-2">
        <div class="col-12 p-3 d-flex flex-column justify-content-center">
          <div class="row text-center my-3 justify-content-center align-items-center py-4">
            <div>
              <img src="assets/img/logo/logo_color.png" alt="Pirog Logo" class="mx-3 logo-custom"/>
            </div>
            <div>
              <p class="display-3 m-auto">c'est...</p>
            </div>
          </div>
          <div class="row text-center">
            <div *ngFor="let id of [1,2,3,4]" class="col-md-6 col-lg-3 py-4">
              <div class="row" style="height: 90px;">
                <h4 class="m-auto display-1 number-custom text-primary font-weight-bold">
                  {{ 'landing.kpi.' + id + '.number' | translate }}
                </h4>
              </div>
              <div class="row">
                <h4 class="m-auto display-1 title-custom text-primary">
                  {{ 'landing.kpi.' + id + '.title' | translate }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-contact-banner></app-contact-banner>
<app-advantages-banner></app-advantages-banner>
