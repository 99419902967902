import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactRequest } from '../models/contact-request.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContactService {


  constructor(private httpClient: HttpClient) {}
  public ContactForm(contactRequest: ContactRequest) {
    return this.httpClient.post<any>(`contacts/pirog/public`, contactRequest);
  }
 
}