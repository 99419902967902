import { Component, Input } from '@angular/core';
import { template } from 'devextreme/core/templates/template';
import { Template } from 'src/app/models/template.model';

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})
export class FilterCardComponent {
  @Input() template: Template
  @Input() isChecked: boolean = false;
}
