<page-header
  [pageName]="'login.title'"
  [mainTitle]="'login.h1.1'"
  [mainTitleClass]="'text-primary'"
  [description]="'login.p.1'"
  [bgColorClass]="'bg-primary-light-3'"
  [iconPath]="'assets/img/icons/common/icon_account.png'">
</page-header>

<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 col-md-6">
      <app-login-section></app-login-section>
    </div>
  </div>
</div>

<app-advantages-banner></app-advantages-banner>
