import { Component } from "@angular/core";

@Component({
  selector: "app-advantages-banner",
  templateUrl: "./advantages-banner.component.html",
  styleUrls: ["./advantages-banner.component.scss"]
})
export class AdvantagesBannerComponent {
  images: { [key: string]: string } = {
    "1": "target.png",
    "2": "customization.png",
    "3": "rating.png",
    "4": "hours.png"
};

  constructor() {}
}
