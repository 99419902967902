import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { selectIsAuth } from "src/app/store/auth/auth.selectors";
import { Store } from "@ngrx/store";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})

export class AboutComponent implements OnInit {
  isAuth$: Observable<boolean>;
  
  constructor(private store: Store, private seoService: SeoService) {}
  ngOnInit() {
    this.seoService.setSeoTags({
      titleKey:"seo.about.title",
      descriptionKey: "seo.about.description",
      imageKey: "seo.about.image"
    });
    this.isAuth$ = this.store.select(selectIsAuth);
  }
}
