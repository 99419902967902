import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { ApplicationService } from 'src/app/services/application.service';
import { TemplateService } from 'src/app/services/template.service';
import { Application } from 'src/app/models/application.model';
import { Template } from 'src/app/models/template.model';
import { PagedResult } from 'src/app/models/paged-result.model';
import { FilteredApplicationsRequest } from 'src/app/models/filtred-applications.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPublic } from 'src/app/models/application-public.model';
import { PlatformService } from 'src/app/services/plateform.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit {

  applicationPagedResult: PagedResult<ApplicationPublic>;
  templates: Template[];
  selectedTemplate: string;
  currentPage: number = 1;
  pageSize: number = 12;

  query: string;
  submittedQuery: string;

  sortFilters: any[] = [];
  selectedSort: string;

  constructor(
    private seoService: SeoService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private templateService: TemplateService,
    private platformeService: PlatformService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(!environment.isExplorerActivated) {
      this.router.navigate(['/']);
    }

    this.route.queryParams
      .subscribe(params => {
        if(params.query != null) {
          this.query = params.query;
        }
      
        this.InitializeSearch();
      }
    );
  }

  InitializeSearch() {
    this.sortFilters = [
      { value: "publicHeadline", label: this.translateService.instant("explore.sort_by_name") },
      { value: "nbOfViews", label: this.translateService.instant("explore.sort_by_views") }
    ];

    this.selectedSort = this.sortFilters[0].value;

    this.fetchAllTemplatesOnSale();
    this.setSeoTags();
    this.fetchApplications();
  }

  setSeoTags(): void {
    this.seoService.setSeoTags({
      titleKey: "seo.explore.title",
      descriptionKey: "seo.explore.description",
      imageKey: "seo.explore.image"
    });
  }

  fetchApplications(): void {
    const request: FilteredApplicationsRequest = {
      page: this.currentPage - 1,
      pageSize: this.pageSize,
      searchTerm: this.query,
      templateType: this.selectedTemplate,
      sort : this.selectedSort
    };

    this.applicationService.getFilteredApplications(request).subscribe(response => {
      this.applicationPagedResult = response;
      this.submittedQuery = this.query;

      if(this.submittedQuery && this.platformeService.isPlatformBrowser()) {
        let target = document.getElementById("apps");
        target.scrollIntoView({behavior: 'smooth'});
      }
    });
  }

  fetchAllTemplatesOnSale(): void {
    this.templateService.getAllTemplatesOnSale().subscribe(response => {
      this.templates = response;
    });
  }

  filterApplicationsByTemplateType(templateName: string): void {
    this.selectedTemplate = templateName;
    this.currentPage = 1;

    this.fetchApplications();
  }

  searchApplications(): void {
    this.currentPage = 1;

    this.fetchApplications();
  }

  pageChanged(event) {
    this.currentPage = event.page;

    this.fetchApplications();
  }

  public resetFilter() {
    this.selectedTemplate = null;

    this.fetchApplications();
  }

  public onSort() {
    this.fetchApplications();
  }
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.searchApplications();
    }
  }
  
}
