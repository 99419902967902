<div class="vh-100 d-flex justify-content-center align-items-center">
    <div class="col-md-4">
        <div class="border border-3 border-success"></div>
        <div class="card  bg-white shadow p-5">
            <div class="text-center">
                <h1>{{ 'upload.h1_0' | translate }}</h1>
                <p><strong>{{ 'upload.strong_0' | translate }}</strong></p>
                <p>{{ 'upload.p_1' | translate }}</p>
                <div class="loading"></div>
            </div>
        </div>
    </div>
</div>
