import { createSelector } from '@ngrx/store';
import { Step } from 'src/app/services/order-local-storage.service';
import { AppState } from '../index';
import { Cart } from './models/cart.model';
import { orderFeatureKey, OrderState } from './order.reducer';

export const orderFeature = (state: AppState) => state[orderFeatureKey];

export const selectApplicationStep = createSelector(orderFeature, (state: OrderState) => state.applicationStep);

export const selectDomainStep = createSelector(orderFeature, (state: OrderState) => state.domainStep);

export const selectOptionsStep = createSelector(orderFeature, (state: OrderState) => state.optionsStep);

export const selectCart = createSelector(orderFeature, (state: OrderState) => {
    var subTotal = 0;
    if(!isNaN(state.applicationStep?.price)) {
        subTotal += state.applicationStep?.price;
    }
    if(!isNaN(state.domainStep?.price)) {
        subTotal += state.domainStep?.price;
    }
    if(!isNaN(state.optionsStep?.price)) {
        subTotal += state.optionsStep?.price;
    }

    subTotal = parseFloat(subTotal.toFixed(2));

    const cart : Cart = {
        appName: state.applicationStep?.appName,
        appNamePrice: state.applicationStep?.price,
        domainChoice: state.domainStep?.offer,
        domainName: state.domainStep?.name,
        domainPrice: state.domainStep?.price,
        piAnalyticsEnabled: state.optionsStep?.piAnalyticsEnabled,
        piAnalyticsPrice: state.optionsStep?.price,
        subTotal: subTotal
    }
    return cart;
});



export const selectCartTotal = createSelector(orderFeature, (state: OrderState) => {
    var total = 0;
    if(!isNaN(state.applicationStep?.price)) {
        total += state.applicationStep?.price;
    }
    if(!isNaN(state.domainStep?.price)) {
        total += state.domainStep?.price;
    }
    if(!isNaN(state.optionsStep?.price)) {
        total += state.optionsStep?.price;
    }
    return parseFloat(total.toFixed(2));
});

export const selectStep = createSelector(orderFeature, (state: OrderState) => state.step);


export const selectTemplateType = createSelector(orderFeature, (state: OrderState) => state.selectedTemplateType);

export const selectUrlOrderFromStep = createSelector(orderFeature, (state: OrderState) => {
    if(state.selectedTemplateType === null) {
        return null;
    } 
    
    let url = ['store', state.selectedTemplateType, 'order'];
    switch (state.step) {
        case Step.Application:
            url.push('application');
            break;
        case Step.Domain:
            url.push('domain');
            break;
        case Step.Options:
            url.push('options');
            break;
        case Step.Payment:
            url.push('payment');
            break;
    }
    return url;
});

export const selectCreationOrder = createSelector(orderFeature, (state: OrderState) => state.creationOder);
