<section>
  <div class="container border-dashed mt-6 py-6">
    <div class="row">
      <div *ngFor="let id of [1,2,3,4]" class="col-6 col-lg-3">
        <div class="text-center my-3">
          <img src="assets/img/icons/common/{{ images[id] }}">
        </div>
        <div>
          <p class="text-center text-primary my-3 lead" 
          [innerHTML]="'components.advantages_banner_home.' + id + '.h3' | translate">
          </p> 
          <p class="text-center my-3">
            {{ 'components.advantages_banner_home.' + id + '.p' | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
