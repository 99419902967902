<div class="d-flex flex-column align-items-center p-5">
  <h1 class="mb-5">{{ "reset_process.h1_0" | translate }}</h1>

  <div class="card bg-primary-light-3 border-0 w-100" style="max-width: 600px">
    <div class="card-body p-lg-5">
      <div class="text-center text-muted mb-4">
        <small> {{ "reset_process.small_0" | translate }} </small>
      </div>
      <form
        role="form"
        (ngSubmit)="onSubmit()"
        [formGroup]="resetPasswordProcessForm"
      >
        <!-- Password Field -->
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
            </div>
            <input
              type="password"
              class="form-control"
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              aria-label="New password"
              placeholder="{{ 'reset_process.input_0' | translate }}"
            />

            <div class="input-group-append">
              <button
                class="btn btn-outline-primary"
                type="button"
                (click)="toggleShowPassword()"
              >
                <i *ngIf="!showPassword" class="fa fa-eye"></i>
                <i *ngIf="showPassword" class="fa fa-eye-slash"></i>
              </button>
            </div>
          </div>
          <div *ngIf="isFieldInvalid('password')" class="text-warning">
            <small *ngIf="hasError('password', 'required')">{{ 'reset_process.password_required' | translate }}</small>
            <small *ngIf="hasError('password', 'minlength')">{{ 'reset_process.password_minlength' | translate }}</small>
          </div>
        </div>

        <!-- Confirm Password Field -->
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="ni ni-curved-next"></i></span>
            </div>
            <input
              type="password"
              class="form-control"
              [type]="showConfirmPassword ? 'text' : 'password'"
              formControlName="confirmPassword"
              aria-label="Confirm password"
              placeholder="{{ 'reset_process.input_1' | translate }}"
              autocomplete="on"
            />

            <div class="input-group-append">
              <button
                class="btn btn-outline-primary"
                type="button"
                (click)="toggleShowConfirmPassword()"
              >
                <i *ngIf="!showConfirmPassword" class="fa fa-eye"></i>
                <i *ngIf="showConfirmPassword" class="fa fa-eye-slash"></i>
              </button>
            </div>
          </div>
          <div *ngIf="isFieldInvalid('confirmPassword')" class="text-warning">
            <small *ngIf="hasError('confirmPassword', 'required')">{{ 'reset_process.confirm_password_required' | translate }}</small>
           
          </div>
          <small *ngIf="hasPasswordMismatchError()"class="text-warning">
            {{ "reset_process.span_0" | translate }}
          </small>
    
        </div>

      

        <!-- Submit Button -->
        <div class="text-center">
          <button type="submit" class="btn btn-primary my-4">
            {{ "reset_process.button_0" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="d-flex justify-content-between w-100" style="max-width: 600px">
    <a routerLink="/login" class="text-primary">
      <small> {{ "reset_process.a_0" | translate }} </small>
    </a>
    <a class="text-primary" [routerLink]="['/register']">
      <small> {{ "reset_process.a_1" | translate }} </small>
    </a>
  </div>
</div>
