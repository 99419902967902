
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { selectIsAuth } from "src/app/store/auth/auth.selectors";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectTemplates } from 'src/app/store/repository/repository.selector';
import { Template } from "src/app/models/template.model";
import { clearCart, setTemplateType } from 'src/app/store/order/order.actions';
import { Router } from '@angular/router';
import { SeoService } from "src/app/services/seo.service";
import { PlatformService } from "src/app/services/plateform.service";
import { TrackingClickAction } from "src/app/models/tracking.model";
import { TrackingService } from "src/app/services/tracking.service";


@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit{
  test: Date = new Date();
  isCollapsed = true;
  isAuth$: Observable<boolean>;
  templates$: Observable<Template[]>;
  selectedTemplate: Template;
  @ViewChild('section3') section3: ElementRef;

  constructor(
    private store: Store, 
    private router: Router, 
    private seoService: SeoService,
    private platformService: PlatformService,
    private trackingService: TrackingService) {}
  
  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey:"seo.landing.title", 
      descriptionKey:"seo.landing.description",
      imageKey: "seo.landing.image"
    });
    this.isAuth$ = this.store.select(selectIsAuth);
    this.templates$ = this.store.select(selectTemplates);

    this.selectedTemplate = {
      templateType: 'piTransfer',
      name: 'piTransfer'
    }
  }
  scrollToSection() {
    if(this.platformService.isPlatformBrowser()){
      this.section3.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
    //this.trackMe();
  }
  public chooseTemplate(template:Template){
    this.selectedTemplate = template;
    this.trackTemplateClick(template);
  }

  redirectToOrder() {
    this.store.dispatch(clearCart());
    this.store.dispatch(setTemplateType({templateType: this.selectedTemplate.templateType}));
    this.router.navigate(['/store', this.selectedTemplate.templateType, 'order']);
  }


  handleTemplateSearch(searchTerm: string) {
    console.log('Search term:', searchTerm);
    // Perform the search logic here
  }  

  trackTemplateClick(template: Template) {
    let trackingDTO: TrackingClickAction = {
      eventType: 'clickAction',
      actionDescription: 'create a ' + template.name + '| Landing Page',
      pageUrl: this.router.url,
      templateId: template.id,
      templateName: template.name,
      btnName: 'caroussel_' + template.id
    }
    this.trackingService.track(trackingDTO);
  }
}

