import {Routes} from '@angular/router';

import {LandingComponent} from './pages/landing/landing.component';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {MentionsComponent} from './pages/mentions/mentions.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {LockComponent} from './pages/lock/lock.component';
import {NoAuthGuard} from 'src/app/guards/no-auth.guard';
import {StoreComponent} from './pages/store/store.component';
import {UploadSuccessComponent} from './pages/upload-success/upload-success.component';
import {AccountValidationComponent} from './pages/account-validation/account-validation.component';
import {ResetPasswordAttemptComponent} from './pages/reset-password-attempt/reset-password-attempt.component';
import {ResetPasswordProcessComponent} from './pages/reset-password-process/reset-password-process.component';
import {AboutComponent} from './pages/about/about.component';
import {ContactComponent} from './pages/contact/contact.component';
import {MicroAppAvailableComponent} from './pages/micro-app-available/micro-app-available.component';
import {ExploreComponent} from './pages/explore/explore.component';
import {CreateAppFormComponent} from './pages/create-app-form/create-app-form.component';
import {NoSsrGuard} from 'src/app/guards/no-ssr.guard';
import {DocComponent} from "./pages/doc/doc.component";

export const publicRoutes: Routes = [
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'micro-app-available/:appName',
    component: MicroAppAvailableComponent,
  },
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    component: LoginComponent,
  },
  {
    path: 'lock',
    component: LockComponent,
  },
  {
    path: 'register',
    canActivate: [NoAuthGuard],
    component: RegisterComponent,
  },
  {
    path: 'pricing',
    component: PricingComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'explore',
    component: ExploreComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'verify',
    canActivate: [NoSsrGuard],
    component: AccountValidationComponent,
  },
  {
    path: 'forgot-password',
    component: ResetPasswordAttemptComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordProcessComponent,
  },
  {
    path: 'success/:applicationId',
    component: UploadSuccessComponent,
  },
  {
    path: "order",
    component: CreateAppFormComponent
  },
  {
    path: 'store',
    component: StoreComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('src/app/components/public/pages/store/store.module')
            .then((m) => {
              return m.StoreModule
            }),
      },
    ],
  },

  {
    path: 'doc',
    component: DocComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../../components/public/pages/doc/doc.module')
            .then((m) => {
              return m.DocModule
            }),
      },
    ],
  },
  {
    path: 'mentions',
    component: MentionsComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  }
];
