<div class="header bg-white py-7 py-lg-8 pt-lg-9">
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8 px-5">

          <h1 class="display-3"> {{'pp.h1_0'|translate}}</h1>
          <h3 class="lead ">{{'pp.h3_0'|translate}}</h3>
          <h3 class="text-lead ">www.pirog.io</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div>
          <h1></h1>
          <p>{{'pp.p_0'|translate}}
            </p>
        </div>
        <div>
          <h1>{{'pp.h1_2'|translate}}</h1>
          <p>{{'pp.p_1'|translate}}
          </p>
        </div>
        <div>
          <h1>{{'pp.h1_3'|translate}}</h1>
          <p>{{'pp.p_2'|translate}}
            <ul>
              <li>{{'pp.li_0'|translate}}</li>
              <li>{{'pp.li_1'|translate}}</li>
              <li>{{'pp.li_2'|translate}}</li>
              <li>{{'pp.li_3'|translate}}</li>
              <li>{{'pp.li_4'|translate}}</li>
              <li>{{'pp.li_5'|translate}}</li>
              <li>{{'pp.li_6'|translate}}</li>
              <li>{{'pp.li_7'|translate}}</li>
            </ul>
            <p>{{'pp.p_3'|translate}} </p>
        </div>
        <div>
          <h1>{{'pp.h1_4'|translate}}</h1>
          <p>{{'pp.p_4'|translate}}</p>
        </div>
        <div>
          <h1>{{'pp.h1_5'|translate}}</h1>
          <p>{{'pp.p_5'|translate}}</p>
          <ul>
            <li>{{'pp.li_8'|translate}}
            </li>
            <li>{{'pp.li_9'|translate}}
            </li>
            <li>{{'pp.li_10'|translate}}
            </li>
            <li>{{'pp.li_11'|translate}}
            </li>
          </ul>
        </div>

        <div>
          <h1>{{'pp.h1_6'|translate}}</h1>
          <p>{{'pp.p_6'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_7'|translate}}</h1>
          <p>{{'pp.p_7'|translate}}</p>
            <ul>
              <li>{{'pp.li_12'|translate}}
              </li>
              <li>{{'pp.li_13'|translate}}</li>
              <li>{{'pp.li_14'|translate}}</li>
              <li>{{'pp.li_15'|translate}}</li>
              <li>{{'pp.li_16'|translate}}
              </li>
              <li>{{'pp.li_17'|translate}}
                <ul>
                  <li>{{'pp.li_18'|translate}}
                  </li>
                  <li>{{'pp.li_19'|translate}}
                  </li>
                </ul>
              </li>
              <li>{{'pp.li_20'|translate}}
              <li>{{'pp.li_21'|translate}}
              </li>
              <li>{{'pp.li_22'|translate}}
              </li>
            </ul>
          <p>{{'pp.p_8'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_8'|translate}}
          </h1>
          <p>{{'pp.p_9'|translate}}</p>
        </div>

        <div>
          <h1>{{'pp.h1_9'|translate}}
          </h1>
          <p>{{'pp.p_10'|translate}}</p>
        </div>

        <div>
          <h1>{{'pp.h1_10'|translate}}
          </h1>
          <p>{{'pp.p_11'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_11'|translate}}
          </h1>
          <p>{{'pp.p_12'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_12'|translate}}
          </h1>
          <p>{{'pp.p_13'|translate}}
            </p>
        </div>

        <div>
          <h1>{{'pp.h1_13'|translate}}
          </h1>
          <p>{{'pp.p_14'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_14'|translate}}
          </h1>
          <p>{{'pp.p_15'|translate}}
            </p>
        </div>

        <div>
          <h1>{{'pp.h1_15'|translate}}
          </h1>
          <p>{{'pp.p_16'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_16'|translate}}
          </h1>
          <p>{{'pp.p_17'|translate}}
          </p>
        </div>

        <div>
          <h1>{{'pp.h1_17'|translate}}
          </h1>
          <p>{{'pp.p_18'|translate}}
            </p>
        </div>
      </div>
    </div>
  </div>

  <div class="separator separator-bottom separator-skew zindex-100">
    <svg
      x="0"
      y="0"
      viewBox="0 0 2560 100"
      preserveAspectRatio="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>


