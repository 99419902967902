<section class="p-4">
  <div class="container d-flex justify-content-center">
    <div class="row d-flex align-items-center justify-content-center gradient-bg gradient-round">
      <div class="d-flex flex-column justify-content-center bg-white gradient-round p-4">
        <form [formGroup]="registerForm" role="form" (ngSubmit)="submitPirogProvider()">
          
          <!-- Firstname Field -->
          <div class="form-group">
            <div class="input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-hat-3"></i></span>
              </div>
              <input
                class="form-control"
                placeholder="{{ 'register.input_0' | translate }}"
                type="text"
                formControlName="firstname"
                aria-label="Firstname"
                name="firstname"
                autocomplete="on"
              />
            </div>
            <div *ngIf="isFieldInvalid('firstname')" class="text-warning">
              <small *ngIf="hasError('firstname', 'required')">{{ 'register.firstname_required' | translate }}</small>
            </div>
          </div>

          <!-- Lastname Field -->
          <div class="form-group">
            <div class="input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-hat-3"></i></span>
              </div>
              <input
                class="form-control"
                placeholder="{{ 'register.input_1' | translate }}"
                type="text"
                formControlName="lastname"
                aria-label="Lastname"
                autocomplete="on"
                name="lastname"
              />
            </div>
            <div *ngIf="isFieldInvalid('lastname')" class="text-warning">
              <small *ngIf="hasError('lastname', 'required')">{{ 'register.lastname_required' | translate }}</small>
            </div>
          </div>

        <!-- Email Field -->
<div class="form-group">
  <div class="input-group input-group-alternative mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="ni ni-email-83"></i></span>
    </div>
    <input
      class="form-control"
      placeholder="Email"
      type="email"
      formControlName="email"
      aria-label="Email"
      name="email"
      autocomplete="on"
    />
  </div>
  <!-- Error Messages Below the Input Group -->
  <div *ngIf="isFieldInvalid('email')" class="text-warning">
    <small *ngIf="hasError('email', 'required')">{{ 'register.email_required' | translate }}</small>
    <small *ngIf="hasError('email', 'email')">{{ 'register.email_invalid' | translate }}</small>
  </div>
  <div class="invalid-feedback" *ngIf="(error$ | async)?.status === 409">
    {{ 'register.find' | translate }}
  </div>
</div>

          <!-- Password Field -->
          <div class="form-group">
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
              </div>
              <input
                class="form-control"
                placeholder="{{ 'register.input_3' | translate }}"
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
                aria-label="Password"
                name="password"
                autocomplete="on"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" (click)="toggleShowPassword()">
                  <i *ngIf="!showPassword" class="fa fa-eye"></i>
                  <i *ngIf="showPassword" class="fa fa-eye-slash"></i>
                </button>
              </div>
            </div>
            <div *ngIf="isFieldInvalid('password')" class="text-warning">
              <small *ngIf="hasError('password', 'required')">{{ 'register.password_required' | translate }}</small>
              <small *ngIf="hasError('password', 'minlength')">{{ 'register.password_min_length' | translate: { minLength: 8 } }}</small>
            </div>
          </div>

          <!-- Agree to Policy Checkbox -->
          <div class="row my-4">
            <div class="col-12">
              <div class="custom-control custom-control-alternative custom-checkbox">
                <input
                  class="custom-control-input"
                  id="customCheckRegister"
                  type="checkbox"
                  formControlName="agreePolicy"
                  aria-label="Agree to Policy"
                  name="agreePolicy"
                  autocomplete="on"
                />
                <label class="custom-control-label" for="customCheckRegister">
                  <span>{{ 'register.span' | translate }}
                    <a [routerLink]="['/privacy']" target="_blank"><strong>{{ 'register.a0' | translate }}</strong></a>
                  </span>
                </label>
              </div>
              <div *ngIf="isFieldInvalid('agreePolicy')" class="text-warning">
                <small>{{ 'register.agree_policy_required' | translate }}</small>
              </div>
            </div>
          </div>

          <!-- Submit Button -->
          <div>
            <button type="submit" class="btn btn-dark mt-4">
              {{ 'register.button.2' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
