import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { signIn } from 'src/app/store/auth/auth.actions';
import { selectErrorAuth } from 'src/app/store/auth/auth.selectors';
import { SeoService } from 'src/app/services/seo.service';
import { Router } from '@angular/router';
import { TrackingLoginRegister } from 'src/app/models/tracking.model';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
  selector: 'app-auth-menu',
  templateUrl: 'auth-menu.component.html',
  styleUrls: ["./auth-menu.component.scss"]
})
export class AuthMenuComponent implements OnInit {
  focus;
  focus1;
  form: FormGroup;
  error$: Observable<any>;
  @Output() closeMenu = new EventEmitter<void>();
  showPassword = false;

  constructor(
    private store: Store,
    private seoService: SeoService,
    private router: Router,
    private trackingService: TrackingService
  ) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      token: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: 'seo.login.title',
      descriptionKey: 'seo.login.description',
      imageKey: 'seo.login.image'
    });

    this.error$ = this.store.select(selectErrorAuth);
  }

  // Helper methods for form validation
  isFieldInvalid(fieldName: string): boolean {
    const control = this.form.controls[fieldName];
    return control && control.invalid && control.touched;
  }

  hasError(fieldName: string, error: string): boolean {
    const control = this.form.controls[fieldName];
    return control && control.hasError(error);
  }

  submitPirogProvider(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.trackLogin();
    const { email, password, token } = this.form.value;
    this.store.dispatch(signIn({ email, password, token }));
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  redirectToNoAccountPage(): void {
    this.router.navigate(['/register']);
    this.closeMenu.emit();
  }

  redirectToForgotPasswordPage(): void {
    this.router.navigate(['/forgot-password']);
    this.closeMenu.emit();
  }

  trackLogin() {
    let trackingDTO: TrackingLoginRegister = {
      eventType: 'login',
      pageUrl: this.router.url
    }
    this.trackingService.track(trackingDTO);
  }
}
