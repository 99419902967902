<page-header
  [pageName]="'pricing.title'"
  [mainTitle]="'pricing.h1.1'"
  [mainTitleDescription]="'pricing.h1.2'"
  [mainTitleClass]="'text-primary'"
  [description]="'pricing.p.1'"
  [bgColorClass]="'bg-primary-light-3'"
  [iconPath]="'assets/img/icons/common/icon_pricing.png'">
</page-header>

<div class="container">
  <section class="my-6">
    <div class="row justify-content-around ">
      <div class="col-12 col-lg-6 px-3">
        <app-paragraph-section
          [titleKey]="'pricing.h2.1'"
          [descriptionKey]="'pricing.p.3'">
        </app-paragraph-section>
      </div>
      <div class="col-12 col-lg-5 bg-custom p-4 m-2 rounded">
        <div class="container d-flex flex-column h-100 justify-content-around text-white">
          <h2 class="display-4 text-white" [innerHTML]="'pricing.h2.2' | translate"></h2>
          <div>
            <p [innerHTML]="'pricing.p.2' | translate"></p>
          </div>
          <div>
            <a *ngIf="(isAuth$ | async) === true" [routerLink]="['/dashboard/applications']" class="btn btn-light text-primary">
              <span class="btn-inner--text"><strong>{{ 'pricing.button.1' | translate }}</strong></span>
            </a>
            <a *ngIf="(isAuth$ | async) === false" [routerLink]="['/register']" class="btn btn-light text-primary">
              <span class="btn-inner--text"><strong>{{ 'pricing.button.1' | translate }}</strong></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="py-4">
    <h2 class="display-2 d-inline" [innerHTML]="'pricing.h2.5' | translate"></h2>
    <img src="assets/img/icons/elements/wheel_secondary.png" class="wheel-primary d-inline">
    <div *ngFor="let template of templates$ | async" class="row align-items-center row-product py-4">
      <div class="col-12 col-lg-9">
        <div class="d-flex flex-column flex-lg-row justify-content-between">
          <div class="col">
            <h2 class="display-3">
              <img src="assets/templates/{{template.name}}/icone_color-min.webp" class="seo_ratio_1 m-3" style="height: 50px;">
              <b>{{ template.name }}</b>
            </h2>
            <ul>
              <li *ngFor="let id of [1,2,3]"> {{ template.id + '.pricing.advantages.' + id | translate }}</li>
            </ul>
          </div>
          <div class="col d-none d-lg-flex text-center m-0 align-items-center">
            <a [routerLink]="['/store/', template.name]" class="btn btn-{{template.name}} text-white border-{{template.name}} w-100 w-lg-auto">
              <span class="btn-inner--text"><strong>{{ template.id + '.pricing.button' | translate }}</strong></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-9">
        <img src="assets/templates/{{template.name}}/{{template.id}}_pricing_fr.png" class="img-fluid">
      </div>
      <div class="col d-flex d-lg-none text-center m-0 align-items-center justify-content-center">
        <a [routerLink]="['/store/', template.name]" class="btn btn-{{template.name}} text-white border-{{template.name}} w-75">
          <span class="btn-inner--text"><strong>{{ template.id + '.pricing.button' | translate }}</strong></span>
        </a>
      </div>
    </div>
  </section>

  <section class="py-6">
    <div class="row rounded bg-primary p-4">
      <div class="col-5 m-auto">
        <div class="overflow-hidden bg-pianalytics rounded d-none d-md-block"></div>
      </div>
      
      <div class="col-12 col-md-7 m-auto">
        <h2 class="display-2 seo_ratio_1_2 text-white"><b>{{ 'pricing.h2.3' | translate }}</b></h2>
        <p class="text-white" [innerHTML]="'pricing.p.4' | translate"></p>
        <button *ngIf="(isAuth$ | async) === true" class="btn btn-light" [routerLink]="['/dashboard/applications']">{{ 'pricing.button.2' | translate }}</button>
        <button *ngIf="(isAuth$ | async) === false" class="btn btn-light" [routerLink]="['/register']">{{ 'pricing.button.2' | translate }}</button>
      </div>
    </div>
  </section>

  <section>
    <app-heading-section
      [titleKey]="'pricing.h2.4'"
      [descriptionKey]="'pricing.p.5'"
      [wheelsArround]="true">
    </app-heading-section>

    <app-three-steps
      [descriptionKey]="'pricing.steps'"
      [stepsImgPath]="'assets/img/icons/common/3_steps.png'"
      [stepsIconesDir]="'assets/img/icons/common/steps/'"
      [buttonKey]="'pricing.button.2'"
      (click)="onButtonClick()">
    </app-three-steps >
  </section>
</div>

<app-products-banner></app-products-banner>
<app-contact-banner></app-contact-banner>
<app-advantages-banner></app-advantages-banner>
