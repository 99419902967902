<div class="header bg-white py-3 py-lg-4 pt-lg-5">
  <div class="container bg-white bg-resources">
    <div class="header-body text-left pb-7">
      <div class="row justify-content-left">
        <div class="col-md-6 text-center">
          <h1 class="display-3 py-2 text-left">{{ 'micro_app_available.title' | translate }}</h1>
          <p class="lead py-3 ">
            <strong>https://<span class="text-secondary">{{ appName }}</span>.pirog.io</strong>
          </p>
          <a [routerLink]="['/order']" class="btn btn-primary my-4 text-center">
            <span class="d-flex btn-inner--text align-items-center">
              {{ 'micro_app_available.button' | translate }}
              <i class="ni ni-bold-right ml-2"></i>
            </span>
          </a>
          <p class="py-2 text-left">{{ 'micro_app_available.text' | translate}}</p>
        </div>
        <div class="col-md-6 p-4 m-auto d-none d-md-block border-left text-center">
          <img src="assets/img/infography/creation_form.png" class="img-fluid mx-auto d-block" width="80%" alt="{{ 'seo.app_creation.img_alt.1' | translate }}" />
        </div>
    </div>
  </div>
</div>
