import { createReducer, on } from '@ngrx/store';
import { Step } from 'src/app/services/order-local-storage.service';
import { ApplicationStep } from './models/application-step.model';
import { DomainStep } from './models/domain-step.model';
import { OptionsStep } from './models/options-step.model';
import { clearCartSuccess, clearCreationOrder, clearCreationOrderSuccess, loadApplicationStepSuccess, loadDomainStepSuccess, loadOptionsStepSuccess, loadTemplateTypeSuccess, setApplicationStepSuccess, setCreationOrder, setCreationOrderSuccess, setDomainStepSuccess, setOptionsStepSuccess, setStepSuccess, setTemplateTypeSuccess } from './order.actions';
import { AppCreationOrder } from './models/creation-order.model';

export const orderFeatureKey = 'order';

export interface OrderState {
    // obsolete
    applicationStep?: ApplicationStep;
    domainStep?: DomainStep;
    optionsStep?: OptionsStep;
    step: Step;
    selectedTemplateType?: string;

    // effective
    creationOder?: AppCreationOrder;
}

export const initialState: OrderState = {
    applicationStep: null,
    domainStep: null,
    optionsStep: null,
    step: null,
    selectedTemplateType: null,

    creationOder: null
};

export const reducer = createReducer(
    initialState,
    on(setApplicationStepSuccess, (state: OrderState, props: { applicationStep: ApplicationStep }) => {
        return {
            ...state,
            applicationStep: props.applicationStep,
        };
    }),
    on(loadApplicationStepSuccess, (state: OrderState, props: { applicationStep: ApplicationStep }) => {
        return {
            ...state,
            applicationStep: props.applicationStep,
        };
    }),
    on(setDomainStepSuccess, (state: OrderState, props: { domainStep: DomainStep }) => {
        return {
            ...state,
            domainStep: props.domainStep,
        };
    }),
    on(loadDomainStepSuccess, (state: OrderState, props: { domainStep: DomainStep }) => {
        return {
            ...state,
            domainStep: props.domainStep,
        };
    }),
    on(setOptionsStepSuccess, (state: OrderState, props: { optionsStep: OptionsStep }) => {
        return {
            ...state,
            optionsStep: props.optionsStep,
        };
    }),
    on(loadOptionsStepSuccess, (state: OrderState, props: { optionsStep: OptionsStep }) => {
        return {
            ...state,
            optionsStep: props.optionsStep,
        };
    }),
    on(setStepSuccess, (state: OrderState, props: { step: Step }) => {
        return {
            ...state,
            step: props.step,
        };
    }),
    on(setTemplateTypeSuccess, (state: OrderState, props: { templateType: string }) => {
        return {
            ...state,
            selectedTemplateType: props.templateType,
        };
    }),
    on(loadTemplateTypeSuccess, (state: OrderState, props: { templateType: string }) => {
        return {
            ...state,
            selectedTemplateType: props.templateType,
        };
    }), 
    on(clearCartSuccess, (state: OrderState) => {
        return {
            ...state,
            domainStep: null,
            optionsStep: null,
            step: Step.Application,
            applicationStep: null,
            selectedTemplateType: null
        };
    }),

    on(setCreationOrderSuccess, (state: OrderState, props: { creationOrder: AppCreationOrder }) => {
        return {
            ...state,
            creationOder: props.creationOrder,
        };
    }),
    on(clearCreationOrderSuccess, (state: OrderState) => {
        return {
            ...state,
            creationOder: null,
        };
    }),
);
