import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ContactService } from 'src/app/services/contact.service';
import { PlatformService } from 'src/app/services/plateform.service';
import { SeoService } from 'src/app/services/seo.service';
import { ToastService } from 'src/app/services/toaster.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { ContactRequest } from 'src/app/models/contact-request.model';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  recaptchaSecretKey = environment.RECAPTCHA_SECRET_KEY;
  cpatchaKey: string;

  form: FormGroup;

  constructor(
    private toastService: ToastService,
    private contactService: ContactService,
    private language: LanguageService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private seoService: SeoService
  ) {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      language: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: 'seo.contact.title',
      descriptionKey: 'seo.contact.description',
      imageKey: 'seo.contact.image'
    });
  }

  resolved(captchaResponse: string): void {
    this.cpatchaKey = captchaResponse;
  }

  isFieldInvalid(fieldName: string): boolean {
    const control = this.form.controls[fieldName];
    return control && control.invalid && control.touched;
  }

  hasError(fieldName: string, error: string): boolean {
    const control = this.form.controls[fieldName];
    return control && control.hasError(error);
  }

  isFormValid(): boolean {
    return this.form.valid && this.cpatchaKey && this.platformService.isPlatformBrowser();
  }

  onSubmit(): void {
    this.form.patchValue({
      language: this.language.getLanguage()
    });

    if (this.isFormValid()) {
      const contactRequest: ContactRequest = {
        name: this.form.controls.name.value,
        email: this.form.controls.email.value,
        subject: this.form.controls.subject.value,
        message: this.form.controls.message.value,
        language: this.form.controls.language.value
      };

      this.contactService.ContactForm(contactRequest).subscribe(() => {
        const message = this.translateService.instant('contact.toastr_message');
        this.toastService.success(message);
        window.scrollTo(0, 0);
      });

      this.ngOnInit();
    } else {
      this.form.markAllAsTouched(); 
    }
  }
}
