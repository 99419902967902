<div class="header bg-white py-7 py-lg-8 pt-lg-9">
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
          <h1>{{ 'not_found.h1' | translate }}</h1>

          <p class="text-lead ">{{ 'not_found.p' | translate }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="separator separator-bottom separator-skew zindex-100">
    <svg
      x="0"
      y="0"
      viewBox="0 0 2560 100"
      preserveAspectRatio="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>

<div class="container mt--8 pb-5">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card card-profile bg-light m-5" style="height: 200px;">
        <div class="row justify-content-center" style="height: 50px;">
          <div class="col-lg-3 order-lg-2">
            <div class="card-profile-image">
              <img
                class="rounded-circle border-primary"
                src="assets/img/theme/404.webp"
              />
            </div>
          </div>
        </div>

        <div class="text-center" style="height: 50px;">
          <a href="/">
          <button class="btn btn-primary mt-5" type="button">
            {{ 'not_found.button' | translate }}
          </button>
        </a>
        </div>
      </div>
    </div>
  </div>
</div>
