import { Component, OnInit } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { SeoService } from "src/app/services/seo.service";

@Component({
  selector: "app-lock",
  templateUrl: "lock.component.html"
})
export class LockComponent implements OnInit {
  focus2;
  constructor(private seoService: SeoService) {}

  ngOnInit() {
    this.seoService.setSeoTags({
      titleKey:"seo.lock.title", 
      descriptionKey:"seo.lock.description", 
      imageKey: "seo.lock.image",
      noindex:true});
  }
}
