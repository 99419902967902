<div class="main-content">
  <page-header
    [pageName]="'about.title'"
    [mainTitle]="'about.h1.1'"
    [mainTitleDescription]="'about.h1.2'"
    [mainTitleClass]="'text-primary'"
    [description]="'about.p.1'"
    [bgColorClass]="'bg-primary-light-3'"
    [iconPath]="'assets/img/icons/common/icon_pirog_color.png'"
   ></page-header>

  <!-- BIRTH -->
  <section class="py-6">
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <app-paragraph-section
              [titleKey]="'about.h2.1'"
              [descriptionKey]="'about.p.2'">
            </app-paragraph-section>
          </div>
          <div class="col-md-6">
            <app-template-youtube
              [youtubeLink]="'https://www.youtube.com/embed/3q2oGj7XK4w'"
            ></app-template-youtube>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- TECHNICAL PROOF -->
  <section class="py-6">
    <div class="container-fluid" style="height: 400px">
      <div class="row">
        <div class="col-md-4 d-flex align-items-top text-center">
          <div
            class="position-absolute d-none d-lg-block"
            style="top: -200px; left: -15px"
          >
            <img
              src="assets/img/theme/computer.png"
              class="seo_ratio_2_1 mx-auto d-block"
            />
          </div>
        </div>
        <div class="col-md-5">
          <app-paragraph-section
            [titleKey]="'about.h2.2'"
            [descriptionKey]="'about.p.3'"
          >
          </app-paragraph-section>
        </div>
        <div class="col-md-3 d-flex align-items-end text-center">
          <div>
            <img
              src="assets/img/icons/elements/two_wheels_2.png"
              class="w-50 seo_ratio_1 mx-auto d-block"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- THE TEAM -->
  <section class="d-flex justify-content-center mt-5">
    <div class="row">
      <div class="col-12">
        <img
          src="assets/img/infography/about.webp"
          class="team-img w-100 mx-auto d-block"
        />
      </div>
      <div class="col-11 m-auto frontal">
        <div
          class="d-flex align-items-center justify-content-center gradient-bg gradient-round"
        >
          <div
            class="row d-flex justify-content-center bg-white gradient-round p-2"
          >
            <div class="col-md-9 p-3 d-flex flex-column justify-content-center">
              <div class="text-center">
                <h2
                  class="display-2 m-auto my-2"
                  [innerHTML]="'about.h2.3' | translate"
                ></h2>
                <p [innerHTML]="'about.p.7' | translate"></p>
              </div>
              <div class="row justify-content-center">
                <div
                  *ngFor="let id of [1, 2, 3, 4, 5]"
                  class="col-md-4 text-center p-3"
                >
                  <div class="p-3">
                    <span
                      class="d-flex avatar rounded-circle profile-custom m-auto"
                    >
                      <img
                        class="bg-primary-light-3 mx-auto d-block"
                        [src]="'assets/img/infography/team/' + id + '.png'"
                      />
                    </span>
                  </div>
                  <h2
                    class="display-4 m-auto m-3"
                    [innerHTML]="'about.team.' + id + '.name' | translate"
                  ></h2>
                  <h2
                    class="display-4 m-auto m-3"
                    [innerHTML]="'about.team.' + id + '.function' | translate"
                  ></h2>
                  <p
                    class="small text-justify"
                    [innerHTML]="
                      'about.team.' + id + '.description' | translate
                    "
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section>
    <app-products-banner></app-products-banner>
  </section>

  <section>
    <app-contact-banner></app-contact-banner>
  </section>

  <section>
    <app-advantages-banner></app-advantages-banner>
  </section>
</div>
