import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CollapseModule} from 'ngx-bootstrap/collapse';

import {publicRoutes} from './public.routing';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LandingComponent} from './pages/landing/landing.component'
import {StoreComponent} from './pages/store/store.component';
import {MentionsComponent} from './pages/mentions/mentions.component';
import {LockComponent} from './pages/lock/lock.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {UploadSuccessComponent} from './pages/upload-success/upload-success.component';
import {AccountValidationComponent} from './pages/account-validation/account-validation.component';
import {ResetPasswordAttemptComponent} from './pages/reset-password-attempt/reset-password-attempt.component';
import {ResetPasswordProcessComponent} from './pages/reset-password-process/reset-password-process.component';
import {CreateAppFormComponent} from './pages/create-app-form/create-app-form.component';
import {ContactComponent} from './pages/contact/contact.component';
import {AboutComponent} from './pages/about/about.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {MicroAppAvailableComponent} from './pages/micro-app-available/micro-app-available.component';
import {ExploreComponent} from './pages/explore/explore.component';
import {FilterCardComponent} from './pages/explore/filter-card/filter-card.component';
import {MicroAppCardComponent} from './pages/explore/micro-app-card/micro-app-card.component';
import {ContactBannerComponent} from './contact-banner/contact-banner.component';
import {AdvantagesBannerComponent} from './advantages-banner/advantages-banner.component';
import {LoginSectionComponent} from './login-section/login-section.component';
import {RegisterSectionComponent} from './register-section/register-section.component';
import {SharedModule} from "../shared/shared.module";
import {NavbarPublicComponent} from "./navbar-public/navbar-public.component";
import {AuthMenuComponent} from "./auth-menu/auth-menu.component";
import {PublicComponent} from "./public.component";

@NgModule({
  declarations: [
    PublicComponent,
    LandingComponent,
    LockComponent,
    NotFoundComponent,
    LoginComponent,
    RegisterComponent,
    PrivacyComponent,
    PricingComponent,
    StoreComponent,
    MentionsComponent,
    UploadSuccessComponent,
    AccountValidationComponent,
    ResetPasswordAttemptComponent,
    ResetPasswordProcessComponent,
    CreateAppFormComponent,
    ContactComponent,
    AboutComponent,
    MicroAppAvailableComponent,
    ExploreComponent,
    FilterCardComponent,
    MicroAppCardComponent,
    ContactBannerComponent,
    AdvantagesBannerComponent,
    LoginSectionComponent,
    RegisterSectionComponent,
    NavbarPublicComponent,
    AuthMenuComponent
  ],
  exports: [
    NavbarPublicComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    SharedModule,
    RecaptchaModule,
    RouterModule.forChild(publicRoutes)
  ]
})

export class PublicModule {
}
