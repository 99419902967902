import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { selectIsAuth } from "src/app/store/auth/auth.selectors";
import { Store } from "@ngrx/store";
import { SeoService } from "src/app/services/seo.service";
import { Template } from "src/app/models/template.model";
import { selectTemplates } from 'src/app/store/repository/repository.selector';
import { Router } from "@angular/router";


@Component({
  selector: "app-pricing",
  templateUrl: "pricing.component.html",
  styleUrls: ["./pricing.component.scss"]
})
export class PricingComponent implements OnInit {
  isAuth$: Observable<boolean>;
  templates$: Observable<Template[]>;
  
  constructor(private store: Store, private seoService: SeoService, private router:Router) {}
  ngOnInit() {
    this.seoService.setSeoTags({
      titleKey:"seo.pricing.title",
      descriptionKey: "seo.pricing.description",
      imageKey: "seo.pricing.image"
    });
    this.isAuth$ = this.store.select(selectIsAuth);
    this.templates$ = this.store.select(selectTemplates);
  }
  onButtonClick(): void {
    this.router.navigate(['/register']);
  }
}
