<page-header
  [pageName]="'register.title'"
  [mainTitle]="'register.h1.1'"
  [mainTitleClass]="'text-primary'"
  [description]="'register.p.1'"
  [bgColorClass]="'bg-primary-light-3'"
  [iconPath]="'assets/img/icons/common/icon_account.png'">
</page-header>

<div class="container">
  <div class="row">
    <div  class="col-12 col-md-6">
      <app-register-section></app-register-section>
    </div>

    <div  class="col-12 col-md-6">
      <app-login-section></app-login-section>
    </div>
  </div>
</div>


<app-advantages-banner></app-advantages-banner>
