<div class="header bg-white py-7 py-lg-8 pt-lg-9">
  <div class="container">
    <div class="header-body text-center mb-7">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8 px-5">
          <h1>Lock screen</h1>

          <p class="text-lead ">Better to be safe than sorry.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="separator separator-bottom separator-skew zindex-100">
    <svg
      x="0"
      y="0"
      viewBox="0 0 2560 100"
      preserveAspectRatio="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
    </svg>
  </div>
</div>

<div class="container mt--8 pb-5">
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card card-profile bg-light mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-3 order-lg-2">
            <div class="card-profile-image">
              <img
                class="rounded-circle border-primary"
                src="assets/img/theme/team-4.png"
              />
            </div>
          </div>
        </div>

        <div class="card-body pt-7 px-5">
          <div class="text-center mb-4"><h3>Jessica Jones</h3></div>

          <form role="form">
            <div class="form-group">
              <div
                class="input-group input-group-merge input-group-alternative"
              >
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="ni ni-lock-circle-open"> </i>
                  </span>
                </div>

                <input
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  name="password"
                  autocomplete="new-password"
                />
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-primary mt-2" type="button">
                Unlock
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
