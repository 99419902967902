import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PlatformService } from '../services/plateform.service';

@Injectable({
  providedIn: 'root',
})
export class NoSsrGuard implements CanActivate {
  constructor(private platformService : PlatformService ) {}

  canActivate(): boolean {
    // Return true only if the platform is the browser (CSR) 
    return this.platformService.isPlatformBrowser();
  }
}
