import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { signIn } from 'src/app/store/auth/auth.actions';
import { selectErrorAuth } from 'src/app/store/auth/auth.selectors';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  form: FormGroup;

  signInGroup: UntypedFormGroup;
  error$: Observable<any>;

  showPassword: boolean = false;

  constructor(private fb: FormBuilder, private store: Store, private seoService: SeoService) {}

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey:"seo.login.title", 
      descriptionKey:"seo.login.description",
      imageKey: "seo.login.image"
    });
  }

  //   this.signInGroup = this.fb.group({
  //     email: ['', [Validators.required, Validators.email]],
  //     password: ['', [Validators.required, Validators.minLength(8)]],
  //     token: [''] 
  //   })

  //   this.error$ = this.store.select(selectErrorAuth);
  // }

  // submitPirogProvider() {
  //   if (this.signInGroup.valid) {
  //     this.store.dispatch(signIn(this.signInGroup.value));
  //   }
  // }

  // get emailCtrl(): UntypedFormControl {
  //   return this.signInGroup.get('email') as UntypedFormControl;
  // }

  // get passwordCtrl(): UntypedFormControl {
  //   return this.signInGroup.get('password') as UntypedFormControl;
  // }
  
  // toggleShowPassword() {
  //   this.showPassword = !this.showPassword;
  // }
}
  