import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
})
export class RegisterComponent implements OnInit {

  constructor(private seoService: SeoService) {}

  ngOnInit(): void {      
    this.seoService.setSeoTags({
      titleKey:"seo.register.title", 
      descriptionKey:"seo.register.description",
      imageKey: "seo.register.image"
    });
  }
}
