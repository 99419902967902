<section>
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col-12 col-md-6 m-auto text-center">
        <img src="../../../../assets/img/icons/common/contact_wheel.png" class="img-fluid px-6 px-sm-4">
      </div>
      <div class="col-12 col-md-6 m-auto">
        <app-paragraph-section
          [titleKey]="'components.contact_banner_home.h2'"
          [descriptionKey]="'components.contact_banner_home.p'"
          [buttonKey]="'components.contact_banner_home.button'"
          [buttonClass]="'btn-primary'"
          [route]="'/contact'">
        </app-paragraph-section>
      </div>
    </div>
  </div>
</section>
