import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { signIn } from 'src/app/store/auth/auth.actions';
import { selectErrorAuth } from 'src/app/store/auth/auth.selectors';
import { SeoService } from 'src/app/services/seo.service';
import { TrackingLoginRegister } from 'src/app/models/tracking.model';
import { Router } from '@angular/router';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
  selector: 'app-login-section',
  templateUrl: 'login-section.component.html',
})
export class LoginSectionComponent implements OnInit, OnDestroy {
  focus;
  focus1;
  loginForm: FormGroup;
  subscription: Subscription = new Subscription();
  error$: Observable<any>;
  showPassword = false;

  constructor(
    private store: Store,
    private router: Router,
    private trackingService: TrackingService,
    private seoService: SeoService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      token: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: 'seo.login.title',
      descriptionKey: 'seo.login.description',
      imageKey: 'seo.login.image',
      noindex: true,
    });

    this.error$ = this.store.select(selectErrorAuth);
  }

  // Helper methods for form validation
  isFieldInvalid(fieldName: string): boolean {
    const control = this.loginForm.controls[fieldName];
    return control && control.invalid && control.touched;
  }

  hasError(fieldName: string, error: string): boolean {
    const control = this.loginForm.controls[fieldName];
    return control && control.hasError(error);
  }

  onSubmit(): void {
    if (!this.loginForm.valid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    const { email, password, token } = this.loginForm.value;
    this.trackLogin();
    this.store.dispatch(signIn({ email, password, token }));
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  trackLogin() {
    let trackingDTO: TrackingLoginRegister = {
      eventType: 'login',
      pageUrl: this.router.url
    }
    this.trackingService.track(trackingDTO);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
