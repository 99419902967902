import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'app-micro-app-available',
  templateUrl: './micro-app-available.component.html',
  styleUrls: ['./micro-app-available.component.scss']
})
export class MicroAppAvailableComponent implements OnInit {
  appName: string;

  constructor(private route: ActivatedRoute, private seoService: SeoService) { }

  ngOnInit(): void {
    this.seoService.setSeoTags({ 
      titleKey: "seo.micro_app_available.title",
      descriptionKey: "seo.micro_app_available.description", 
      imageKey: "seo.micro_app_available.image",
      noindex: true });
    this.getParamsFromRoute();
  }

  private getParamsFromRoute(): void {
    this.route.params.subscribe((params) => {
      this.appName = params['appName'];
    });
  }
}
