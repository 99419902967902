<div class="content bg-white">
  <page-header
    [pageName]="'explore.page_name'"
    [mainTitleClass]="'text-primary'"
    [bgColorClass]="'bg-primary-light-3'"
    [mainTitle]="'explore.title'"
    [mainTitleDescription]="'explore.title_description'"
    [description]="'explore.description'"
    [bgColorClass]="'bg-primary-light-3'"
    [iconPath]="'assets/img/icons/common/loop.png'">
  </page-header>

  <div class="container spaced-search-content" id="apps">
    <div class="row justify-content-center">
      <div class="col-md-8 m-auto">
        <div class="input-group input-group-alternative input-group-merge search-bar-container bg-primary-light-3">
          <input
            class="form-control form-control-lg"
            placeholder="{{ 'explore.search_apps' | translate }}" type="text" [(ngModel)]="query" (keydown)="onKeydown($event)"
          />
          <a class="input-group-append cursor-pointer" (click)="searchApplications()">
            <span class="input-group-text"><i class="fas fa-xl fa-search mr-2"></i></span>
          </a>
        </div>
      </div>
      <div class="col-md-4 m-auto ">
        <h2 *ngIf="submittedQuery && applicationPagedResult" class="text-center mb-0 mt-0">
          {{applicationPagedResult.totalElements}} {{ 'explore.results_for' | translate }} {{submittedQuery}}
        </h2>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row justify-content-between">
      <div class="col-md-5 mt-3">
        <div class="d-flex justify-content-between">
          <h2 class="text-left"><i class="fas fa-filter"></i> Filtrer par type : </h2>
          <div><a class="btn btn-sm btn-outline-primary" (click)="resetFilter()">{{ 'reset' | translate }} x</a></div>
        </div>

        <div class="row" >
            <div  class="col-md-3 col-6 p-3" *ngFor="let template of templates">
              <a (click)="filterApplicationsByTemplateType(template.name)">
                <app-filter-card [template]="template" [isChecked]="selectedTemplate === template.name"></app-filter-card>
              </a>
            </div>
        </div>
      </div>
      <div class="col-md-4 mt-3">
        <div class="form-group ">
          <div class="d-flex justify-content-center">
            <h2 class="m-auto"><i class="fas fa-filter"></i> Trier : </h2>
            <select class="form-control sort-select w-50 bg-primary-light-3" id="exampleFormControlSelect3" [(ngModel)]="selectedSort" (change)="onSort()">
              <option *ngFor="let sortFilter of sortFilters" [ngValue]="sortFilter.value">{{sortFilter.label}}</option>
            </select>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="header">
    <div class="container">
      <div class="row">
        <div *ngFor="let application of applicationPagedResult?.content" class="col-md-3">
          <app-micro-app-card [application]="application"></app-micro-app-card>
        </div>
      </div>

      <div *ngIf="applicationPagedResult?.totalElements > 0" class="d-flex justify-content-center mt-4 mb-5">
        <pagination
          [totalItems]="applicationPagedResult.totalElements"
          [itemsPerPage]="pageSize"
          [(ngModel)]="currentPage"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          (pageChanged)="pageChanged($event)">
        </pagination>
      </div>
    </div>
    </div>
</div>
