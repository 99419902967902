<div class="mt-4">
  <div class="profile-card">
    <a href="https://{{ application.domainUrl }}" target="_blank">
      <img src="{{ application.publicImage }}" class="img img-responsive seo_ratio_16_9 w-100">
    </a>
  </div>
  <div class="d-flex justify-content-between w-100">
    <div class="pr-4">
      <h2 class="text-left public-headline">{{ application.publicHeadline }}</h2>
      <p class="text-left public-description mt-2">{{application.publicDescription}}</p>
    </div>
    <div>
      <img class="app-icon" src="{{'assets/templates/' + application.templateType + '/icone_color-min.webp'}}" />
      <div class="app-icon text-center views mt-1"><i class="fa fa-eye"></i>{{application.nbOfViews ?? 0}}</div>
    </div>
  </div>
</div>
